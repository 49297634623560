import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import {
  Typography,
  Button,
  Input,
  InputNumber,
  InputPrice,
  BorderLine,
  DatePicker,
  enqueueSnackbar,
} from '../../design-system';
import { DashboardHeader, DashboardForm, DashboardFormCustomColumn } from '../dashboard';
import { useAddPricingMutation, useStoragePricingForm } from '../../hooks';
import { formatContainerType, getNormalFormProps } from '../../utils';
import { getCache } from '../../services';
import {
  MESSAGES,
  INPUT_ERRORS,
  pricingDetails,
  pricingFormLabels,
  containerTypes,
  Pricing,
} from '../../const';
import { StoragePricingsAddCache, StoragePricingAddFormProps, ContainerType } from '../../types';

let formProps = {} as StoragePricingAddFormProps;

const getSubmitProps = () => {
  const validate = {
    freeDays: String(formProps.freeDays).length,
    ...['loaded', 'empty', 'loadedCraneIn', 'loadedCraneOut', 'emptyCraneIn', 'emptyCraneOut'].map(
      (key) => ({
        [key]: containerTypes
          .map((containerType) => formProps[containerType.value][key as keyof Pricing])
          .every((val: number) => String(val).length !== 0),
      }),
    ),
  };

  if ([...Object.values(validate)].some((val) => !val)) {
    return;
  }

  return getNormalFormProps(formProps);
};

export const StoragePricingsAddHeader = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate } = useAddPricingMutation();

  const handleSubmitClick = () => {
    formProps.onSubmitClick();
    const submitProps = getSubmitProps();
    if (!submitProps) return;

    mutate(
      { ...submitProps, id: params.id },
      {
        onSuccess: () => {
          enqueueSnackbar(MESSAGES.STORAGE_PRICING.SUCCESS.ADDED, 'success');
          navigate('/dashboard/storage-pricing');
        },
      },
    );
  };

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Add Pricing
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const StoragePricingsAddForm = () => {
  const [yardName] = useState(getCache<StoragePricingsAddCache>('storage-pricing-add')?.yardName);
  const [clientName] = useState(
    getCache<StoragePricingsAddCache>('storage-pricing-add')?.clientName,
  );
  const [{ effectiveDate, freeDays, ...containers }, setPricingState] = useStoragePricingForm({
    effectiveDate: moment(),
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  useEffect(() => {
    formProps = {
      ...formProps,
      yardName,
      clientName,
      effectiveDate,
      freeDays,
      ...containers,
      onSubmitClick: () => {
        setIsSubmitClicked(true);
      },
    };
  }, [
    yardName,
    clientName,
    effectiveDate,
    freeDays,
    ...pricingDetails.map((detail) =>
      Object.keys(containers)
        .map((containerType) => containers[containerType as ContainerType][detail])
        .join(''),
    ),
  ]);

  const handleEffectiveDateClick = (pricingDate: Moment) => {
    setPricingState((props) => {
      props.effectiveDate = pricingDate;
    });
  };

  return (
    <DashboardForm paperSize={'full-size'}>
      <DashboardFormCustomColumn columns={'1fr 1fr 1fr 1fr'}>
        <Input label={'Client'} data-size={'medium'} defaultValue={clientName} readOnly />
        <Input label={'Yard'} data-size={'medium'} defaultValue={yardName} readOnly />
        <DatePicker
          label={'Effective Date'}
          data-size={'medium'}
          defaultValue={effectiveDate}
          onChange={handleEffectiveDateClick}
          withoutClearIcon
        />
        <InputNumber
          label={'Free Days'}
          data-size={'medium'}
          defaultValue={freeDays}
          onChange={(e) =>
            setPricingState((props) => {
              props.freeDays = Number(e.target.value);
            })
          }
          error={isSubmitClicked ? !String(freeDays).length : false}
          helperText={
            isSubmitClicked ? (!String(freeDays).length ? INPUT_ERRORS.REQUIRED.FREE_DAYS : '') : ''
          }
        />
      </DashboardFormCustomColumn>
      <BorderLine space={'dashboard-form'} />
      {Object.keys(containers).map((containerType, i) => (
        <DashboardFormCustomColumn
          key={`container-${i}`}
          columns={'1fr 1fr 1fr 1fr 1fr 1fr'}
          align={'start'}
        >
          {pricingDetails.map((detail, n) => (
            <InputPrice
              key={`detail-${n}`}
              label={pricingFormLabels[detail](formatContainerType(containerType as ContainerType))}
              data-size={'medium'}
              defaultValue={containers[containerType as ContainerType][detail]}
              onChange={(e) => {
                setPricingState((props) => {
                  props[containerType as ContainerType][detail] = Number(e.target.value);
                });
              }}
              error={
                isSubmitClicked
                  ? !String(containers[containerType as ContainerType][detail]).length
                  : false
              }
              helperText={
                isSubmitClicked
                  ? !String(containers[containerType as ContainerType][detail]).length
                    ? INPUT_ERRORS.REQUIRED.PRICE
                    : ''
                  : ''
              }
            />
          ))}
        </DashboardFormCustomColumn>
      ))}
    </DashboardForm>
  );
};
