import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableHeadCell,
  Button,
  Chip,
  InfoItem,
} from '../../design-system';
import { DashboardFormCards, DashboardFormCard, DashboardHeader } from '../dashboard';
import { Filters } from './Filters';
import { momentTextFormat, formatContainerType } from '../../utils';
import { ContainerIsLoadedToChipLabels, ContainerIsLoadedToChipColors } from '../../const';
import { User, ReportsGetContainersResponse } from '../../types';
import { useSaveFilters } from '../../hooks';

interface ReportsPageProps {
  innerScroll?: boolean;
}

export const ReportsPage = styled.div<ReportsPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

interface ReportsHeaderProps {
  totalCount: number;
  handleExportExcelClick: () => void;
  handleShowReportsClick: () => void;
}

export const ReportsHeader = ({
  totalCount,
  handleExportExcelClick,
  handleShowReportsClick,
}: ReportsHeaderProps) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Reports: {totalCount}
      </Typography>
      <Button size={'medium'} onClick={handleExportExcelClick} disabled={!totalCount}>
        Export Excel
      </Button>
      <Button size={'medium'} onClick={handleShowReportsClick}>
        Show Reports
      </Button>
    </DashboardHeader>
  );
};

export const ReportsPageInfos = ({ children }: any) => {
  return <DashboardFormCards id={'reports-cards'}>{children}</DashboardFormCards>;
};

interface ReportsGeneralInfoProps {
  user: User;
  startDate?: Date;
  endDate?: Date;
}

export const ReportsGeneralInfo = ({ user, startDate, endDate }: ReportsGeneralInfoProps) => {
  const [filters] = useSaveFilters('belongs-to', false);

  return (
    <DashboardFormCard paperSize={'full-size'}>
      <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
        General Information
      </Typography>
      <InfoItem label={'Client'} value={filters.clientName} />
      <InfoItem label={'Country'} value={filters.country} />
      <InfoItem label={'City'} value={filters.city} />
      <InfoItem label={'Yard'} value={filters.yardName} />
      <InfoItem label={'From'} value={startDate && momentTextFormat(startDate, user)} />
      <InfoItem label={'To'} value={endDate && momentTextFormat(endDate, user)} />
    </DashboardFormCard>
  );
};

export const ReportsPriceInfo = ({ data }: { data: ReportsGetContainersResponse['data'] }) => {
  const storageFee = data.reduce((result, container) => result + container.daysInTerminalPrice, 0);
  const craneFee = data.reduce(
    (result, container) => result + container.gateInCranePrice + container.gateOutCranePrice,
    0,
  );
  const allFee = storageFee + craneFee;

  return (
    <DashboardFormCard paperSize={'full-size'}>
      <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
        Price Information
      </Typography>
      <InfoItem label={'Storage Fee'} value={`${storageFee.toFixed(2)}$`} />
      <InfoItem label={'Crane Fee'} value={`${craneFee.toFixed(2)}$`} />
      <InfoItem label={'All Fee'} value={`${allFee.toFixed(2)}$`} />
    </DashboardFormCard>
  );
};

interface ReportsBodyProps {
  user: User;
  data: ReportsGetContainersResponse['data'];
  isSubmitClicked: boolean;
  states: { [key: string]: any };
  setStates: (key: string, value?: any, isEmptyFilter?: boolean) => void;
}

export const ReportsBody = ({
  user,
  data,
  isSubmitClicked,
  states,
  setStates,
}: ReportsBodyProps) => {
  return (
    <TableWrapper columns={[21.2, 20, 20, 20, 20, 20, 10, 10, 10]}>
      <Filters user={user} isSubmitClicked={isSubmitClicked} setStates={setStates} {...states} />
      {!!data.length && (
        <Table
          headType={'light'}
          bodyRowSize={'small'}
          showLastRowBorder={true}
          renderTableHeadRow={
            <TableRow>
              <TableHeadCell align={'left'}>Container Number</TableHeadCell>
              <TableHeadCell align={'left'}>Container Type</TableHeadCell>
              <TableHeadCell align={'left'}>Gate-In Date From</TableHeadCell>
              <TableHeadCell align={'left'}>Gate-Out Date To</TableHeadCell>
              <TableHeadCell align={'left'}>Days In Terminal</TableHeadCell>
              <TableHeadCell align={'left'}>Paying Days</TableHeadCell>
              <TableHeadCell align={'left'}>Is Loaded</TableHeadCell>
              <TableHeadCell align={'left'}>Storage Fee</TableHeadCell>
              <TableHeadCell align={'left'}>Crane In/Out Fee</TableHeadCell>
            </TableRow>
          }
          renderTableBodyRows={data.map((container, i) => (
            <TableRow key={'storage-pricing' + i}>
              <TableCell component={'th'} align={'left'}>
                {container.number}
              </TableCell>
              <TableCell align={'left'}>{formatContainerType(container.type)}</TableCell>
              <TableCell align={'left'}>
                {momentTextFormat(container.gateIn.completedAt, user)}
              </TableCell>
              <TableCell align={'left'}>
                {container.gateOut?.completedAt &&
                  momentTextFormat(container.gateOut?.completedAt, user)}
              </TableCell>
              <TableCell align={'left'}>{container.daysInTerminal} Days</TableCell>
              <TableCell align={'left'}>{container.payingDays} Days</TableCell>
              <TableCell align={'left'}>
                <Chip
                  size={'small'}
                  label={ContainerIsLoadedToChipLabels[container.isLoadedText]}
                  color={ContainerIsLoadedToChipColors[container.isLoadedText]}
                />
              </TableCell>
              <TableCell align={'left'}>{container.daysInTerminalPrice.toFixed(2)}$</TableCell>
              <TableCell align={'left'}>
                {container.gateInCranePrice}$ / {container.gateOutCranePrice}$
              </TableCell>
            </TableRow>
          ))}
        />
      )}
    </TableWrapper>
  );
};
