import { Moment } from 'moment';
import { PricingDetails, ContainerType } from '../types';

export const pricingDetails: PricingDetails = [
  'loaded',
  'empty',
  'loadedCraneIn',
  'loadedCraneOut',
  'emptyCraneIn',
  'emptyCraneOut',
];

interface StoragePricingBase {
  loaded: number;
  empty: number;
  loadedCraneIn: number;
  loadedCraneOut: number;
  emptyCraneIn: number;
  emptyCraneOut: number;
}

export type StoragePricing = Record<ContainerType, StoragePricingBase> & {
  freeDays: number;
  effectiveDate: Moment | string;
};

export interface Pricing {
  loaded: number;
  empty: number;
  loadedCraneIn: number;
  loadedCraneOut: number;
  emptyCraneIn: number;
  emptyCraneOut: number;
}

export interface StoragePricingContainers {
  '20DC': Pricing;
  '20HC': Pricing;
  '40DC': Pricing;
  '40HC': Pricing;
  '45HC': Pricing;
}

const PRICING = {
  loaded: 0,
  empty: 0,
  loadedCraneIn: 0,
  loadedCraneOut: 0,
  emptyCraneIn: 0,
  emptyCraneOut: 0,
};

export const STORAGE_PRICING = {
  freeDays: 0,
  '20DC': PRICING,
  '20HC': PRICING,
  '40DC': PRICING,
  '40HC': PRICING,
  '45HC': PRICING,
};
