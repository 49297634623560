import { Moment } from 'moment';
import { StoragePricingContainers, StoragePricingStatusToLabels } from '../const';
import { containerTypesLabel } from '../utils';

export enum AccountStatus {
  ACTIVE = 'ACTIVATE',
  DEACTIVATED = 'DEACTIVATE',
}

export enum BelongsTo {
  SUPER_ADMIN = 'SUPER_ADMIN',
  YARD_COMPANY = 'YARD_COMPANY',
  YARD = 'YARD',
  CLIENT = 'CLIENT',
}

type UserType =
  | 'YARD_COMPANY_ADMIN'
  | 'YARD_COMPANY_EMPLOYEE'
  | 'YARD_MANAGER'
  | 'YARD_DISPATCHER'
  | 'CLIENT_MANAGER'
  | 'CLIENT_DISPATCHER';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountStatus: AccountStatus;
  belongsTo: BelongsTo;
  yardCompanyEmployee: YardCompanyEmployee;
  yardEmployee: YardEmployee;
  clientEmployee: ClientEmployee;
  createdAt: Date;
  updatedAt: Date;
  type: UserType & any;
}

interface YardCompanyEmployee {
  yardCompanyId: string;
  role: string;
  yardCompany: { legalName: string };
}

interface YardEmployee {
  yardId: string;
  role: string;
  yard: { name: string };
}

interface ClientEmployee {
  clientId: string;
  role: string;
  client: { legalName: string };
}

export interface ImageObjType {
  action: 'upload' | 'delete';
  file: any;
  name?: string;
}

export type SortDirection = 'asc' | 'desc';

export type ContainerType = '20DC' | '20HC' | '40DC' | '40HC' | '45HC';

export interface ContainerGetAllQuery {
  data: {
    number: string;
    id: string;
    type: keyof typeof containerTypesLabel;
    status: 'GATED_IN' | 'GATED_OUT' | 'GATE_OUT_REQUESTED';
    images: { url: string }[];
    clientYard: {
      client: {
        code: string;
      };
    };
    gateIn: {
      completedAt: number | string | Date;
    };
    gateOut: {
      completedAt: number | string | Date;
    };
  }[];
  totalCount: number;
}

export type PricingDetails = [
  'loaded',
  'empty',
  'loadedCraneIn',
  'loadedCraneOut',
  'emptyCraneIn',
  'emptyCraneOut',
];

export interface FiltersWithPaginationProps {
  take: number;
  skip: number;
  [key: string]: any;
}

export type FiltersSetStatesProp = (key: string, value?: any, isEmptyFilter?: boolean) => void;

export interface StoragePricingGetAllRequest {
  params: FiltersWithPaginationProps;
}

export interface StoragePricingGetAllResponse {
  data: {
    id: string;
    storagePricing: {
      id: string;
      activePricingId: string;
      pricingStatus: keyof typeof StoragePricingStatusToLabels;
      freeDays: number;
      activationDate: string;
      pricing: {
        id: string;
        freeDays: number;
        activationDate: string;
      }[];
    };
    client: {
      code: string;
    };
    yard: {
      name: string;
      yardCompany: {
        legalName: string;
      };
    };
  }[];
  totalCount: number;
}

export interface GateInGetAllQuery {
  data: {
    id: string;
    number: string;
    type: keyof typeof containerTypesLabel;
    clientYard: {
      client: {
        code: string;
      };
    };
    gateIn: {
      status: 'ACTIVE' | 'EXPIRED' | 'COMPLETED';
      driver: {
        firstName: string;
        lastName: string;
        licensePlateNumber: string;
      };
      createdAt: number | string | Date;
      completedAt: number | string | Date;
      validFrom: number | string | Date;
      validTo: number | string | Date;
    };
  }[];
  totalCount: number;
}

export interface GateOutGetAllQuery {
  data: {
    id: string;
    number: string;
    type: keyof typeof containerTypesLabel;
    clientYard: {
      client: {
        code: string;
      };
    };
    gateOut: {
      authorizedLicensePlateNumbers: string[];
      comment: string;
      status: 'ACTIVE' | 'EXPIRED' | 'COMPLETED';
      driver: {
        licensePlateNumber: string;
      };
      createdAt: number | string | Date;
      completedAt: number | string | Date;
      validFrom: number | string | Date;
      validTo: number | string | Date;
    };
  }[];
  totalCount: number;
}

export interface EmployeeGetAllQuery {
  data: {
    user: { accountStatus: 'ACTIVE' | 'DEACTIVATED' } & Omit<User, 'accountStatus'>;
    firstName: string;
    lastName: string;
    role:
      | 'YardCompanyAdmin'
      | 'YardCompanyEmployee'
      | 'YardManager'
      | 'YardDispatcher'
      | 'ClientManager'
      | 'ClientDispatcher';
    yardCompany: { legalName: string };
    yard: { name: string };
    client: { legalName: string };
    companyName: string;
  }[];
  totalCount: number;
}

export interface StoragePricingGetOneResponse {
  data: {
    id: string;
    clientYard: StoragePricingUpdateModalCache['clientYard'];
    pricing: StoragePricingUpdateModalCache['activePricing'][];
  };
}

export interface StoragePricingsAddCache {
  yardName: string;
  clientName: string;
}

export interface StoragePricingAddFormProps extends StoragePricingContainers {
  yardName: string;
  clientName: string;
  effectiveDate: string | Moment | Date;
  freeDays: number;
  onSubmitClick: () => void;
}

export interface StoragePricingAddMutationProps extends StoragePricingContainers {
  id: string;
  freeDays: number;
  effectiveDate: Moment;
}

export interface StoragePricingAddMutationRequest {
  id: string;
  activationDate: string;
  freeDays: number;
  containers: {
    type: string;
    loaded: number;
    empty: number;
    loadedCraneIn: number;
    loadedCraneOut: number;
    emptyCraneIn: number;
    emptyCraneOut: number;
  }[];
}

export interface StoragePricingUpdateModalCache {
  id: string;
  refetch: () => void;
  open: () => void;
  activePricing: {
    id: string;
    containers: {
      type: ContainerType;
      loaded: number;
      empty: number;
      loadedCraneIn: number;
      loadedCraneOut: number;
      emptyCraneIn: number;
      emptyCraneOut: number;
    }[];
    activationDate: string;
    freeDays: number;
  };
  clientYard: {
    client: {
      code: string;
    };
    yard: {
      name: string;
    };
  };
}

export interface StoragePricingUpdateMutationProps extends StoragePricingContainers {
  id: string;
  activePricingId: string;
  freeDays: number;
}

export interface StoragePricingUpdateMutationRequest {
  id: string;
  activePricingId: string;
  freeDays: number;
  containers: {
    type: string;
    loaded: number;
    empty: number;
    loadedCraneIn: number;
    loadedCraneOut: number;
    emptyCraneIn: number;
    emptyCraneOut: number;
  }[];
}

export interface ReportsGetContainersQueryProps {
  yardCompanyId?: string;
  yardId?: string;
  clientId?: string;
  startDate?: string | Date;
  endDate?: string | Date;
}

export interface ReportsGetContainersResponse {
  data: {
    allDaysInTerminal: number;
    allPrice: number;
    daysInTerminal: number;
    daysInTerminalPrice: number;
    freeDays: number;
    payingDays: number;
    gateIn: { completedAt: string };
    gateInCranePrice: 0;
    gateOut?: { completedAt?: string };
    gateOutCranePrice: 0;
    isLoaded: null | boolean;
    isLoadedText: 'LOADED' | 'EMPTY';
    number: string;
    type: ContainerType;
  }[];
  totalCount: 0;
}
