import { TableRow, TableCell } from '@mui/material';
import styled from 'styled-components';
import {
  Typography,
  Button,
  Table,
  TableWrapper,
  TableHeadCell,
  TableWrapperHeader,
} from '../../design-system';
import { DashboardHeader } from '../dashboard';
import { formatContainerType, momentTextFormat } from '../../utils';
import { getCache, saveCache } from '../../services';
import { StoragePricingGetOneResponse, StoragePricingUpdateModalCache, User } from '../../types';

const StoragePricingTableWrapperHeader = styled(TableWrapperHeader)`
  & > *:nth-child(2) {
    position: sticky;
    right: 0;
  }
`;

interface StoragePricingsViewHeaderProps {
  isFirstIndex: boolean;
}

export const StoragePricingsViewHeader = ({ isFirstIndex }: StoragePricingsViewHeaderProps) => {
  return (
    <DashboardHeader>
      <span />
      {isFirstIndex && (
        <Button size={'medium'} data-color={'white'} to={-1}>
          Go Back
        </Button>
      )}
    </DashboardHeader>
  );
};

interface StoragePricingsTableProps {
  data: StoragePricingGetOneResponse['data'];
  pricing: StoragePricingGetOneResponse['data']['pricing'][number];
  user: User;
  refetch: () => void;
}

export const StoragePricingsTable = ({
  data,
  pricing,
  user,
  refetch,
}: StoragePricingsTableProps) => {
  const handleUpdateClick = () => {
    saveCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal', (props) => ({
      ...props,
      id: data.id,
      clientYard: data.clientYard,
      activePricing: pricing,
      refetch,
    }));

    getCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal').open();
  };

  return (
    <TableWrapper columns={[10, 10, 10]}>
      <StoragePricingTableWrapperHeader align={'right'}>
        <Typography variant={'textL'} weight={'bold'}>
          Effective Date: {momentTextFormat(pricing.activationDate, user)} --- Free Days:{' '}
          {pricing.freeDays}
        </Typography>
        <Button size={'medium'} onClick={handleUpdateClick}>
          Update
        </Button>
      </StoragePricingTableWrapperHeader>
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Type</TableHeadCell>
            <TableHeadCell align={'left'}>Loaded</TableHeadCell>
            <TableHeadCell align={'left'}>Empty</TableHeadCell>
            <TableHeadCell align={'left'}>Crane Fee: Loaded In</TableHeadCell>
            <TableHeadCell align={'left'}>Crane Fee: Loaded Out</TableHeadCell>
            <TableHeadCell align={'left'}>Crane Fee: Empty In</TableHeadCell>
            <TableHeadCell align={'left'}>Crane Fee: Empty Out</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={pricing.containers.map((row, i) => (
          <TableRow key={'storage-pricing-table-row' + i}>
            <TableCell component={'th'} align={'left'}>
              {formatContainerType(row.type)}
            </TableCell>
            <TableCell align={'left'}>{row.loaded}$</TableCell>
            <TableCell align={'left'}>{row.empty}$</TableCell>
            <TableCell align={'left'}>{row.loadedCraneIn}$</TableCell>
            <TableCell align={'left'}>{row.loadedCraneOut}$</TableCell>
            <TableCell align={'left'}>{row.emptyCraneIn}$</TableCell>
            <TableCell align={'left'}>{row.emptyCraneOut}$</TableCell>
          </TableRow>
        ))}
      />
    </TableWrapper>
  );
};
